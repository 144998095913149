import React from 'react'
import LandingBk from '../Assets/web.jpg'
import { Link } from 'react-router-dom'
import '../Styles/landingPage.css'
import { Fade } from 'react-awesome-reveal'




function LandingPage() {
  return (
    <div className='landing-page-container'>
        <div className="landing-bk">
            <img src={LandingBk} alt="landing-background-image" />
        </div>
       
        <div className="landing-text">
            <Fade direction='up' ><h5>Welcome to</h5></Fade>
           <Fade direction='up'> <h1>Pruudent Technologies Limited </h1></Fade>
            <Fade direction='up' ><p>Pruudent Technologies Inc (PT) is an emerging player in the rapidly evolving and growing Software as a Service (SaaS) sector of the Information and Technology (IT) ecosystem.</p></Fade>
            <div className="landing-buttons">
           <Fade > <button className='learn'>
                    <Link to='/Services'> Learn More</Link>
                
            </button></Fade>
            <Fade ><button className='request'>    <Link to='/Contact'> Request Call-back</Link></button></Fade>
        </div>
        </div>

     

      
    </div>
  )
}

export default LandingPage